<script>
export default {
  components: {
  },
  ValidaErro(error) {
    let notification = ''
    let msgErroCatch
    try {
      if (error.toString().includes('Network Error') === false) {
        if (error.response.data.mensagem !== '' && error.response.data.mensagem != null) {
          msgErroCatch = error.response.data.mensagem
        } else if (error.response.data.erros[0] != null) {
          msgErroCatch = error.response.data.erros[0].toString()
        }
      } else {
        msgErroCatch = 'Erro de Conexão com a API. Verifique sua Conexão e tente novamente.'
      }
    } catch (e) {
      msgErroCatch = `Erro Generico: ${e} - Verifique com a TI`
    }
    notification = { message: msgErroCatch, alert_type: 'error' }
    return notification
  },
  MsgFiltroSemRetorno() {
    let notification = { message: 'Nenhum Resultado Encontrado. Valide os valores informados e tente novamente.', alert_type: 'attention' }
    return notification
  },
}

</script>
